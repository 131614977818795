'use strict';

window.addEventListener('load', () => {
    const openMenu = document.querySelector('.openMenu');
    const closeMenu = document.querySelector('.closeMenu');
    const menu = document.querySelector('nav');
    const body = document.querySelector('body');
    let error = false;

    const headerTop = document.querySelector('.header_top');
    const main = document.querySelector('main');

    const openMenuFunc = () => {
        menu.classList.add('active_nav');
        body.classList.add('left');
    };

    const closeMenuFunc = () => {
        menu.removeAttribute('class');
        body.removeAttribute('class');
    };

    openMenu.addEventListener('click', openMenuFunc);

    closeMenu.addEventListener('click', () => {
        closeMenuFunc();
    });
    
    window.addEventListener('mouseup', (e) => {
        const ul = menu.querySelectorAll('ul');
        const form = menu.querySelectorAll('form');
    
        function childArr (arr) {
            arr.forEach(item => {
                item.addEventListener('click', openMenuFunc);
            });
        }

        if (menu.classList.contains('active_nav') && body.classList.contains('left')) {
            childArr(ul);
            childArr(form);
            
            if (e.target !== menu) {
                closeMenuFunc();
            }
        }
    });

    const setNavBg = () => {
        if (window.scrollY > main.offsetTop - 91) {
            headerTop.style.backgroundColor = '#f1f1f1';
        } else {
            headerTop.style.backgroundColor = '';
        }
    };

    window.addEventListener('scroll', () => {
        setNavBg();
    });

    setNavBg();

    const btnUp = document.querySelector('.btnUp');

    const docUp = () => {
        if (window.scrollY >= 600) {
            btnUp.classList.add('btnShow');
        } else {
            btnUp.classList.remove('btnShow');
        }
    };

    window.addEventListener('scroll', docUp);

    btnUp.addEventListener('click', () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    const isEmpty = (node) => {
        const arr = Array.from(node);
        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];

            if (element.value.trim() === '') {
                error = true;
                element.style.borderColor = '#ED3237';
            } else {
                element.style.borderColor = '#29BB00';
            }
        }
    };

    // choose application
    try {
        const btn = document.querySelector('.selected');
        const options = document.querySelector('.options');
        const label = document.querySelectorAll('.app_label input');
        const selectedLabel = document.querySelector('.app_label input[selected]');

        btn.innerHTML = selectedLabel.parentNode.textContent;

        btn.addEventListener('click', () => {
            options.style.display = 'block';
        });

        window.addEventListener('mouseup', (e) => {
            if (e.target !== options) {
                options.style.display = 'none';
            }
        });

        for (let i = 0; i < label.length; i++) {
    
            label[i].addEventListener('click', () => {
    
                for (let x = 0; x < label.length; x++) {
                    label[x].removeAttribute('selected');
                }
    
                label[i].setAttribute('selected', '');
                btn.innerHTML = label[i].parentNode.textContent;
                options.style.display = 'none';
            });
        }
    } catch(err){}

    // choose exchanges
    try {
        const choose = (node) => {
            const btn = Array.from(document.querySelectorAll(node));

            for (let i = 0; i < btn.length; i++) {
                btn[i].addEventListener('click', () => {
                    for (let x = 0; x < btn.length; x++) {
                        btn[x].removeAttribute('selected');
                        btn[x].parentElement.classList.remove('active_label');
                    }

                    btn[i].setAttribute('selected', '');
                    btn[i].parentElement.classList.add('active_label');
                });
            }
        };

        choose('[data-card] input');
        choose('[data-bank] input');
        choose('[data-anonym] input');
    } catch(err){}

    // apply page
    try{
        const applyForm = document.querySelector('.apply_form');
        const applyInputs = document.querySelectorAll('.apply_form input, textarea');
        const regExp = /^[+-]?\d+$/;

        const modal = (title, message, btnName) => {
            const footer = document.querySelector('footer');

            footer.insertAdjacentHTML('afterend', `
                <div class="response_modal">
                    <div class="rm_body">
                        <h3>${title}</h3>
                        <p>${message}</p>
                        <button class="close_rm btn">${btnName}</button>
                    </div>
                </div>
            `);

            try {
                const closeRm = document.querySelector('.close_rm');
                const rmModal = document.querySelector('.response_modal');

                closeRm.addEventListener('click', () => {
                    rmModal.style.display = 'none';
                    window.location = '/';
                });
            } catch (e){}
        };

        applyForm.addEventListener('submit', (e) => {
            e.preventDefault();
            
            isEmpty(applyInputs);

            if (!regExp.test(applyForm[1].value.trim())) {
                error = true;
                applyForm[1].style.borderColor = '#ED3237';
            }

            if (error === true) {
                e.preventDefault();
            } 
            
            else {
                const formData = new FormData(applyForm);

                fetch('#', {
                    method: 'POST',
                    body: formData
                }).then((response) => {
                    return response.json();
                }).then(data => {
                    const db = JSON.parse(data);
                    modal(db["title"], db["message"], db["btnName"]);
                }).catch( () => {
                    modal('Error', `Serever Error. Please try again !`, 'Close');
                }).finally(() => {
                    applyForm.reset();
                });
            }
        });
    } catch(err) {}

    // card form
    try {
        const donateForm = document.querySelector('.card_form');
        const donateInput = document.querySelectorAll('.card_form input[type=text], input[type=email]');
        const donatePrice = document.querySelector('.card_form input[type=number]');

        donateForm.addEventListener('submit', (e) => {
            isEmpty(donateInput);
            if (donatePrice.value == 0) {
                e.preventDefault();
                error = true;
                donatePrice.style.borderColor = '#ED3237';
            } else {
                error = false;
                donatePrice.style.borderColor = '#29BB00';
            }

            for (let i = 0; i < donateInput.length; i++) {
                if (donateInput[i].value.trim() === '') {
                    error = true;
                } else {
                    error = false;
                }
            }

            if (error === true) {
                e.preventDefault();
            } else {
                e.stopPropagation();
            }
        });
    } catch(err){}

    // tab
    try {
        const tab = (btnSel, bodySel, tabBtnClass, tabBodyClass) => {
            const tabBtn = document.querySelectorAll(btnSel);
            const tabBody = document.querySelectorAll(bodySel);
    
            for (let i = 0; i < tabBtn.length; i++) {
    
                tabBtn[i].addEventListener('click', () => {
        
                    for (let x = 0; x < tabBtn.length; x++) {
                        tabBtn[x].classList.remove(tabBtnClass);
                        tabBody[x].classList.remove(tabBodyClass);
                    }
        
                    tabBtn[i].classList.add(tabBtnClass);
                    tabBody[i].classList.add(tabBodyClass);
                });
            }
        };
        tab('.tab_btn', '.tab_body', 'active_tab', 'active_tab_body');
        tab('[data-bank]', '.rekvisit_tab', null, 'active_rekvisit');
    } catch(err) {}

    //close payment modal
    try {
        const closePy = document.querySelector('.close_py');
        const pyModal = document.querySelector('.paymentModal');

        closePy.addEventListener('click', () => {
            pyModal.style.display = 'none';
        });
    } catch(err) {}
});